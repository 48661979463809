// src/main.js

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

const app = createApp(App);

// 配置 Axios
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || 'https://vdsentnet-services.hydun.com:9890'; // 后端 API 地址

// 添加请求拦截器，自动添加 JWT
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // 从 localStorage 获取 JWT
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// 将 Axios 挂载到全局
app.config.globalProperties.$http = axios;

// 使用路由器
app.use(router);

// 挂载应用
app.mount('#VDSEntertainmentNetworkAPP');