<template>
    <div class="container">
      <div class="avatar-container" :class="{ 'bounce': isAuthenticated }">
        <img v-if="!isAuthenticated" src="/img/EMlogo-large1x.png" class="logo" alt="Logo" />
        <img v-else :src="avatarUrl" class="avatar" alt="User Avatar" />
      </div>
      <h2 class="slogan" v-if="!isAuthenticated">一个账户，畅享服务</h2>
      <h2 class="slogan" v-else>欢迎回来，{{ displayName }}</h2>
      <div class="button-group" v-if="!isAuthenticated">
        <div class="button-container">
          <router-link to="/login">
            <button class="login-btn">登录</button>
          </router-link>
          <router-link to="/register">
            <button class="register-btn">注册</button>
          </router-link>
        </div>
      </div>
      <div class="button-group" v-else>
        <div class="button-container">
          <router-link to="/manage">
            <button class="login-btn">管理我的账户</button>
          </router-link>
        </div>
      </div>
      <div id="starfield"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
    data() {
      return {
        hasJumped: false,
        isAuthenticated: false,
        avatarUrl: '/img/EMlogo-large1x.png',
        displayName: ''
      };
    },
    async mounted() {
      document.title = "VDS Entertainment Network (Beta版)";
      this.$nextTick(() => {
        this.createStarfield();
      });
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/userinfo`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.data) {
          this.isAuthenticated = true;
          this.avatarUrl = response.data.avatarUrl;
          this.displayName = response.data.nickname || response.data.username;
          
        }
        } catch (err) {
          console.error('获取用户信息失败:', err);
        }
      }
    },
    methods: {
      createStarfield() {
        const starfield = document.getElementById('starfield');
        if (!starfield) return;
        for (let i = 0; i < 200; i++) {
          const star = document.createElement('div');
          star.className = 'star';
          star.style.position = 'absolute';
          star.style.top = Math.random() * 100 + 'vh';
          star.style.left = Math.random() * 100 + 'vw';
          star.style.width = '2px';
          star.style.height = '2px';
          star.style.backgroundColor = '#fff';
          star.style.borderRadius = '50%';
          star.style.animation = 'twinkling 6s infinite ease-in-out';
          star.style.animationDuration = 2 + Math.random() * 4 + 's';
          star.style.animationDelay = Math.random() * 4 + 's';
          starfield.appendChild(star);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  #starfield {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
  }
  
  .star {
    position: absolute;
    width: 1px;
    height: 1px;
    background-color: #ffffff;
    opacity: 0.2;
    border-radius: 50%;
    animation: twinkling 6s infinite ease-in-out;
  }
  
  @keyframes twinkling {
    0%,
    100% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
  }
  
  .avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
  }
  
  
  .logo {
    height: 50px;
  }
  
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .slogan {
    margin-top: 20px;
    font-size: 1.5em;
    color: #fff;
    z-index: 1;
  }
  
  .login-btn {
    background-color: #006aff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50px;
    margin-right: 10px;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
    z-index: 1;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .register-btn {
    background-color: #006aff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50px;
  }

  @media (max-width: 768px) {
    .logo {
        height: 25px;
    }
  }
  </style>