<template>
  <div class="edit-profile-container">
    <div class="edit-profile-header">
      <h1>信息</h1>
    </div>
    <div class="user-details">
      <div class="user-field">
        <p><strong>头像:</strong></p>
        <div class="avatar-container">
          <img :src="avatarUrl" class="user-avatar" alt="User Avatar" />
        </div>
        <i class="fas fa-edit" @click="triggerFileSelect"></i>
        <input type="file" ref="fileInput" accept="image/*" @change="handleImageUpload" style="display: none;" />
      </div>
      <div class="user-field">
        <p><strong>昵称:</strong> {{ nickname || '(暂未设置)' }}</p>
        <i class="fas fa-edit" @click="openEditModal('昵称')"></i>
      </div>
      <div class="user-field">
        <p><strong>电子邮件地址:</strong> {{ email }}</p>
        <i class="fas fa-edit" @click="openEditModal('电子邮件地址')"></i>
      </div>
      <div class="user-field">
        <p><strong>手机号:</strong> {{ maskedPhoneNumber }}</p>
      </div>
    </div>
    <transition name="modal-fade">
      <div v-if="showEditModal" class="modal-overlay">
        <transition name="modal-zoom">
          <div class="modal">
            <div class="modal-header">
              <h3 class="hardoff" style="color: black; font-size: 25px;">编辑 {{ fieldToEdit }}</h3>
              <p class="close-button" @click="closeEditModal">&times;</p>
            </div>
            <input type="text" v-model="editValue" placeholder="请输入新的内容" />
            <button @click="saveEdit" :disabled="!editValue">确认</button>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import Compressor from 'compressorjs';

export default {
  name: 'EditProfilePage',
  data() {
    return {
      avatarUrl: '',
      nickname: '',
      phoneNumber: '',
      email: '',
      showEditModal: false,
      fieldToEdit: '',
      editValue: ''
    };
  },
  computed: {
    maskedPhoneNumber() {
      if (this.phoneNumber.length === 11) {
        return this.phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
      }
      return this.phoneNumber;
    }
  },
  async created() {
    try {
      const response = await this.$http.get('/edit-profile');
      this.avatarUrl = response.data.avatarUrl || '';
      this.nickname = response.data.nickname || '';
      this.phoneNumber = response.data.phoneNumber || '';
      this.email = response.data.email || '';
    } catch (err) {
      console.error('获取用户信息失败', err);
    }
  },
  methods: {
    triggerFileSelect() {
      this.$refs.fileInput.click();
    },
    async handleImageUpload(event) {
  const file = event.target.files[0];
  if (!file) return;

  const vm = this; // 保存 Vue 实例的引用

  try {
    // 使用 Compressor.js 压缩图片
    new Compressor(file, {
      quality: 0.8,
      maxWidth: 500,
      maxHeight: 500,
      convertSize: 0,
      success: async (compressedFile) => {
        console.log('压缩后的文件：', compressedFile);

        // 创建 FormData 对象
        const formData = new FormData();
        formData.append('image', compressedFile, 'avatar.jpg');

        // 获取令牌
        const token = vm.$store ? vm.$store.state.token : localStorage.getItem('token');

        // 检查令牌是否存在
        if (!token) {
          console.error('未找到令牌，无法进行身份验证');
          return;
        }

        // 发送 POST 请求到 /upload-image API
        const response = await vm.$http.post('/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });

        // 获取返回的图片 URL
        const imageUrl = response.data.imageUrl;

        // 更新用户的 avatarUrl
        await vm.$http.post('/edit-profile', {
          avatarUrl: imageUrl,
        });

        // 更新组件中的 avatarUrl，以便更新显示
        vm.avatarUrl = imageUrl;

        // 可选：显示成功消息
        vm.$emit('refresh-user');
      },
      error(err) {
        console.error('压缩图片时出错', err);
      },
    });
  } catch (err) {
    console.error('上传头像失败', err);
  }
},

    openEditModal(field) {
      this.fieldToEdit = field;
      this.editValue = this[field === '昵称' ? 'nickname' : field === '电子邮件地址' ? 'email' : ''];
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.editValue = '';
    },
    async saveEdit() {
      try {
        await this.$http.post('/edit-profile', {
          [this.fieldToEdit === '昵称' ? 'nickname' : 'email']: this.editValue
        });
        if (this.fieldToEdit === '昵称') {
          this.nickname = this.editValue;
        } else if (this.fieldToEdit === '电子邮件地址') {
          this.email = this.editValue;
        }
        this.showEditModal = false;
        this.editValue = '';
        this.$emit('refresh-user');
      } catch (err) {
        console.error('更新信息失败', err);
      }
    }
  }
};
</script>

<style scoped>

.edit-profile-container {
  padding: 20px;
}

.edit-profile-header {
  margin-bottom: 20px;
}

h1 {
  font-family: 'MiSans-Bold', Arial, sans-serif;
  font-size: 2em;
  color: white;
}

.user-details {
  margin-top: 20px;
}

.user-field {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: white;
}

.user-field i {
  margin-left: 10px;
  cursor: pointer;
  color: #007bff;
}

.user-field i:hover {
  color: #0056b3;
}

.avatar-container {
  position: relative;
  display: inline-block;
}

.edit-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: #007bff;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
}

.edit-icon:hover {
  color: #0056b3;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.modal {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  position: relative;
  width: 400px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -30px;
  margin-top: -30px;
}

.close-button {
  font-size: 1.5em;
  cursor: pointer;
  color: #000;
}

.modal input[type="text"] {
  width: 99%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal button {
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  font-size: 1em;
}

.modal button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.modal button:hover:not(:disabled) {
  background-color: #0056b3;
}

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.3s ease;
}

.modal-fade-enter-from, .modal-fade-leave-to {
  opacity: 0;
}

.modal-zoom-enter-active, .modal-zoom-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.modal-zoom-enter-from, .modal-zoom-leave-to {
  transform: scale(0.1);
  opacity: 0;
}

.modal-zoom-enter-to, .modal-zoom-leave-from {
  transform: scale(1);
  opacity: 1;
}
</style>