<template>
  <div class="register-container">
    <div class="left-side">
      <div class="promo-content">
        <div class="invert-container">
          <img src="/img/EMlogo-large1x.png" class="logo" alt="Logo" />
        </div>
        <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">加入我们</h2>
        <p style="font-family: 'MiSans-Light', Arial, sans-serif;">注册 VDS Entertainment Network，开始探索无限精彩</p>
      </div>
    </div>
    <div class="right-side">
      <div class="mdui-card register">
        <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">注册</h2>

        <!-- 阶段一：注册 -->
        <form v-if="!isVerificationStage" @submit.prevent="handleRegister">
          <div>
            <label>用户名:</label>
            <input type="text" v-model="username" required class="input-field" @input="validateUsername" />
            <transition name="expand" appear>
              <p v-if="usernameError" class="error">{{ usernameError }}</p>
            </transition>
          </div>
          <div>
            <label>手机号码:</label>
            <input type="text" v-model="phoneNumber" required class="input-field" />
          </div>
          <div>
            <label>电子邮件地址:</label>
            <input type="email" v-model="email" class="input-field" />
          </div>
          <div>
            <label>密码:</label>
            <input type="password" v-model="password" @input="showConfirmPassword" required class="input-field" />
          </div>
          <transition name="expand" appear>
            <div v-if="showConfirm" class="confirm-password">
              <label>确认密码:</label>
              <input type="password" v-model="confirmPassword" required class="input-field" />
              <p v-if="passwordMismatch" class="error">密码不匹配</p>
            </div>
          </transition>
          <button type="submit" :disabled="!isFormValid">发送验证码</button>
        </form>

        <!-- 阶段二：输入验证码 -->
        <form v-if="isVerificationStage" @submit.prevent="handleVerifyCode">
          <div>
            <label>输入您收到的验证码:</label>
            <input type="text" v-model="verificationCode" required class="input-field" />
          </div>
          <button type="submit" :disabled="!verificationCode">验证并完成注册</button>
        </form>

        <p v-if="error" class="error">{{ error }}</p>
        <p v-if="success" class="success">{{ success }}</p>
        <p>已有账号？<router-link class="router-link" :to="loginLink">登录</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register-page',
  data() {
    return {
      usernameError: '',
      username: '',
      password: '',
      confirmPassword: '',
      avatarUrl: 'https://static.vdsentnet.com/userstatic/default_avatar.png',
      nickname: '',
      phoneNumber: '',
      email: '',
      error: '',
      success: '',
      showConfirm: false,
      passwordMismatch: false,
      isVerificationStage: false, // 控制注册和验证码阶段的切换
      verificationCode: '' // 保存用户输入的验证码
    };
  },
  computed: {
    isFormValid() {
      return this.username && !this.usernameError && this.password && this.confirmPassword && this.password === this.confirmPassword && this.phoneNumber;
    },
    loginLink() {
      const next = this.$route.query.next;
      return next ? { path: '/login', query: { next } } : '/login';
    }
  },
  methods: {
    validateUsername() {
      const usernameRegex = /^[a-zA-Z0-9]*$/;
      if (!usernameRegex.test(this.username)) {
        this.usernameError = '用户名只能是英文字符或数字的组合，如你需要设置昵称，请在账户创建后操作。';
      } else {
        this.usernameError = '';
      }
    },
    async handleRegister() {
      // 注册阶段：发送验证码
      if (this.password !== this.confirmPassword) {
        this.error = '密码不匹配';
        return;
      }
      try {
        const response = await this.$http.post('/register', {
          username: this.username,
          password: this.password,
          avatarUrl: this.avatarUrl,
          nickname: this.nickname,
          phoneNumber: this.phoneNumber,
          email: this.email,
        });
        this.success = response.data.message || '验证码已发送，请查收短信';
        this.error = '';
        this.isVerificationStage = true; // 进入验证码输入阶段
      } catch (err) {
        this.error = err.response.data.error || '发送验证码失败';
        this.success = '';
      }
    },
    async handleVerifyCode() {
      // 验证阶段：验证验证码并完成注册
      try {
        const response = await this.$http.post('/register/verify', {
          phoneNumber: this.phoneNumber,
          verificationCode: this.verificationCode,
        });
        this.success = response.data.message || '注册成功，请登录';
        this.error = '';
        const nextUrl = this.$route.query.next;
        this.$router.push(nextUrl ? { path: '/login', query: { next: nextUrl } } : '/login');
      } catch (err) {
        this.error = err.response.data.error || '验证码验证失败';
        this.success = '';
      }
    },
    showConfirmPassword() {
      if (this.password.length > 0) {
        this.showConfirm = true;
      } else {
        this.showConfirm = false;
        this.confirmPassword = '';
      }
      this.passwordMismatch = this.password !== this.confirmPassword && this.confirmPassword.length > 0;
    }
  }
};
</script>

<style scoped>
.register-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.promo-content {
  text-align: center;
  color: white;
}

.logo {
  height: 50px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.mdui-card {
  color: white;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  background-color: rgba(47, 47, 47, 0.3);
  border-radius: 15px;
  transition: transform 0.5s ease;
}

.mdui-card.enter-active {
  transform: scale(1.2);
}

.mdui-card.leave-active {
  transform: scale(0.8);
}

button[type="submit"] {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button[type="submit"]:hover:not(:disabled) {
  background-color: #0056b3;
}

.router-link {
  text-decoration: none;
  color: #007bff;
}

.router-link:hover {
  color: #0056b3;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.expand-enter-to {
  max-height: 100px;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .left-side {
    display: none;
  }

  .right-side {
    flex: 1;
    padding: 10px;
  }

  .mdui-card {
    max-width: 90%;
  }
}
</style>