<template>
  <Header />
    <div class="pubcontents">
      <router-view />
    </div>
  <Footer />
</template>

<script>
import Header from './components/VC/nav.vue';
import Footer from './components/VC/footer.vue';

export default {
components: {
  Header,
  Footer,
},
};
</script>

<style>
body {
  font-family: 'MiSans', Arial, sans-serif;
  background-color: black; /* 设置背景为纯黑色 */
}
/* 全局样式，移除页面整体的默认边距和内边距 */
html, body {
margin: 0;
padding: 0;
box-sizing: border-box;
width: 100%;
overflow-x: hidden; /* 避免横向滚动条 */
}
.pubcontents {
margin-top: 55px;
}
</style>