<template>
  <div class="user-info-container">
    <div class="sidebar" :class="{ open: sidebarOpen }">
      <div class="user-header">
        <img v-if="user" :src="user.avatarUrl" class="user-avatar" alt="User Avatar" />
        <div class="user-details" v-if="user">
          <p class="user-name">{{ user.nickname || user.username }}</p>
          <p class="user-email">{{ user.email }}</p>
        </div>
      </div>
      <nav class="nav-menu">
        <ul>
          <li :class="{ active: isActive('dash') }" @click="navigateTo('dash')">
            <i class="fas fa-dashboard"></i>
            <span>仪表板</span>
          </li>
          <li :class="{ active: isActive('edit-profile') }" @click="navigateTo('edit-profile')">
            <i class="fas fa-user"></i>
            <span>你的信息</span>
          </li>
          <li :class="{ active: isActive('logout') }" @click="handleLogout">
            <i class="fas fa-sign-out-alt"></i>
            <span>退出登录</span>
          </li>
        </ul>
      </nav>
    </div>
    <div class="user-info">
      <button class="toggle-sidebar" @click="toggleSidebar">&#9776;</button>
      <router-view class="content-view" @refresh-user="fetchUserInfo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserContainer',
  data() {
    return {
      user: null,
      error: '',
      sidebarOpen: false,
    };
  },
  async created() {
    await this.fetchUserInfo();
  },
  methods: {
    async fetchUserInfo() {
      try {
        const response = await this.$http.get('/userinfo');
        this.user = response.data;
      } catch (err) {
        this.error = err.response.data.error || '获取用户信息失败';
      }
    },
    isActive(routeName) {
      return this.$route.name === routeName;
    },
    navigateTo(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName }).catch(() => {});
        this.sidebarOpen = false;
      }
    },
    async handleLogout() {
      try {
        await this.$http.post('/logout');
        localStorage.removeItem('token');
        this.$router.push('/login');
      } catch (err) {
        console.error(err);
      }
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
  },
};
</script>

<style scoped>
.user-info-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  width: 250px;
  border-right: 1px solid #444;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  position: fixed;
  left: 0;
  height: calc(100% - 55px);
  z-index: 2000;
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.user-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  flex: 1;
}

.user-name {
  font-family: 'MiSans-Bold', Arial, sans-serif;
  font-size: 20px;
  margin: 0;
}

.user-email {
  color: #aaa;
  margin: 0;
  font-size: 0.9em;
}

.nav-menu {
  margin-top: 20px;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
}

.nav-menu li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px;
  border-radius: 5px;
}

.nav-menu li:hover {
  background-color: #007bff;
  color: #fff;
}

.nav-menu li.active {
  background-color: #007bff;
  color: #fff;
}

.nav-menu i {
  margin-right: 10px;
}

.user-info {
  flex: 1;
  padding: 20px;
  overflow: auto;
  position: relative;
  width: 100%;
  transition: margin-left 0.3s ease;
}

.toggle-sidebar {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
}

@media (min-width: 768px) {
  .sidebar {
    position: relative;
    transform: translateX(0);
  }
  .toggle-sidebar {
    display: none;
  }
}

@media (max-width: 768px){
  .sidebar {
    background-color: #3c3c3c;
  }
}
</style>