<template>
    <div class="authorize-container">
        <div class="left-side">
            <div class="promo-content">
                <div class="invert-container">
                    <img src="/img/EMlogo-large1x.png" class="logo" alt="Logo" />
                </div>
                <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">授权请求</h2>
            </div>
        </div>
        <div class="right-side">
            <div class="mdui-card authorize">
                <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">授权应用</h2>
                <div v-if="authRequest && clientName">
                    <p>应用 <strong>{{ clientName }}</strong> 请求访问您的账户。</p>
                    <p>权限范围: {{ scopeDescription }}</p>
                    <button @click="handleApprove">同意</button>
                    <button @click="handleDeny">拒绝</button>
                </div>
                <div v-else>
                    <p>加载中...</p>
                </div>
                <p v-if="error" class="error">{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Authorize-page',
    data() {
        return {
            authRequest: null,
            clientName: '',
            error: '',
        };
    },
    computed: {
        scopeDescription() {
            return this.authRequest && this.authRequest.scope === 'read' ? '读取你的所有信息' : this.authRequest.scope;
        }
    },
    async created() {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const clientID = urlParams.get('client_id');
            const redirectURI = urlParams.get('redirect_uri');
            const scope = urlParams.get('scope');
            const responseType = urlParams.get('response_type');
            const state = urlParams.get('state');

            if (!clientID || !redirectURI || !scope || !responseType) {
                this.error = '缺少必要的授权参数';
                return;
            }

            this.clientName = clientID;

            const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/oauth/authorize`, {
                params: {
                    client_id: clientID,
                    redirect_uri: redirectURI,
                    scope: scope,
                    response_type: responseType,
                    state: state,
                }
            });

            if (response.data && response.data.client) {
                this.authRequest = {
                    client: response.data.client,
                    scope: response.data.scope,
                    state: response.data.state,
                    transactionID: response.data.transactionID,
                    clientID: clientID,
                    redirectURI: redirectURI
                };
            } else {
                this.error = '未能正确获取授权请求信息';
            }
        } catch (err) {
            this.error = err.response ? err.response.data.error : '授权请求失败';
        }
    },
    methods: {
        async handleApprove() {
            try {
                const token = localStorage.getItem('token');
                const response = await this.$http.post(`${process.env.VUE_APP_API_URL}/oauth/authorize/decision`, {
                    allow: true,
                    transactionID: this.authRequest.transactionID,
                    clientId: this.authRequest.clientID,
                    redirectUri: this.authRequest.redirectURI,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                window.location.href = `${this.authRequest.redirectURI}?code=${response.data.code}`;
            } catch (err) {
                this.error = err.response && err.response.data ? err.response.data.error : '授权失败';
            }
        },
        async handleDeny() {
            try {
                await this.$http.post(`${process.env.VUE_APP_API_URL}/oauth/authorize/decision`, {
                    allow: false,
                    transactionID: this.authRequest.transactionID,
                    clientId: this.authRequest.clientID,
                    redirectUri: this.authRequest.redirectURI,
                });
                window.location.href = this.authRequest.redirectURI;
            } catch (err) {
                this.error = err.response && err.response.data ? err.response.data.error : '拒绝授权失败';
            }
        },
    },
};
</script>

<style scoped>
.authorize-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.left-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.right-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.promo-content {
    text-align: center;
    color: white;
}

.logo {
    height: 50px;
}

.mdui-card {
    color: white;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    background-color: rgba(47, 47, 47, 0.3);
    border-radius: 15px;
    transition: transform 0.5s ease;
}

.mdui-card.enter-active {
    transform: scale(1.2);
}

.mdui-card.leave-active {
    transform: scale(0.8);
}

button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

button:hover {
    background-color: #0056b3;
}

.error {
    color: red;
    margin-top: 10px;
}

.router-link {
    text-decoration: none;
    color: #007bff;
}

.router-link:hover {
    color: #0056b3;
}

@media (max-width: 1000px) {
    .left-side {
        display: none;
    }

    .right-side {
        flex: 1;
        padding: 10px;
    }

    .mdui-card {
        max-width: 90%;
    }
}
</style>