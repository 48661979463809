<template>
  <div class="login-container">
    <div class="left-side">
      <div class="promo-content">
        <div class="invert-container">
          <img src="/img/EMlogo-large1x.png" class="logo" alt="Logo" />
        </div>
        <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">欢迎回来</h2>
        <p style="font-family: 'MiSans-Light', Arial, sans-serif;">登录 VDS Entertainment Network，尽享VDS一切服务</p>
      </div>
    </div>
    <div class="right-side">
      <div class="mdui-card login">
        <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">登录</h2>
        <form @submit.prevent="handleLogin">
          <div>
            <label>用户名/手机号码/电子邮件地址:</label>
            <input type="text" v-model="username" required class="input-field" />
          </div>
          <div>
            <label>密码:</label>
            <input type="password" v-model="password" required class="input-field" />
          </div>
          <button type="submit">登录</button>
        </form>
        <p v-if="error" class="error">{{ error }}</p>
        <p>没有账号？<router-link class="router-link" :to="registerLink">创建一个</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login-page',
  data() {
    return {
      username: '',
      password: '',
      error: ''
    };
  },
  computed: {
    registerLink() {
      const next = this.$route.query.next;
      return next ? { path: '/register', query: { next } } : '/register';
    }
  },
  created() {
    const token = localStorage.getItem('token');
    if (token) {
      this.$router.push('/manage');
    }
  },
  methods: {
    async handleLogin() {
      try {
        const response = await this.$http.post('/login', {
          username: this.username,
          password: this.password,
        });
        localStorage.setItem('token', response.data.token);
        const nextUrl = this.$route.query.next;
        if (nextUrl) {
          this.$router.push(decodeURIComponent(nextUrl));
        } else {
          this.$router.push('/manage');
        }
      } catch (err) {
        this.error = err.response.data.error || '登录失败';
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.promo-content {
  text-align: center;
  color: white;
}

.logo {
  height: 50px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error {
  color: red;
  margin-top: 10px;
}

.mdui-card {
  color: white;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  background-color: rgba(47, 47, 47, 0.3);
  border-radius: 15px;
}

button[type="submit"] {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.router-link {
  text-decoration: none;
  color: #007bff;
}

.router-link:hover {
  color: #0056b3;
}

@media (max-width: 1000px) {
  .left-side {
    display: none;
  }

  .right-side {
    flex: 1;
    padding: 10px;
  }

  .mdui-card {
    max-width: 90%;
  }
}
</style>